.white {
  font-size: 12px;
  color: white !important;
}

.white {
  font-size: 12px;
  color: white !important;
  padding-left: 15px;
}

.pad {
  padding-top: 100px !important;
}
