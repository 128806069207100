@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oxanium&display=swap');

.margin {
  margin: 10px !important;
}

.borderblue {
  font-family: 'Roboto', sans-serif;
  font-weight: 800 !important;
  font-size: 30px !important;
  border-bottom: #0d4eaf 10px solid;
  padding-left: 5px;
  padding-right: 5px;
}

.borderred {
  font-family: 'Roboto', sans-serif;
  font-weight: 800 !important;
  font-size: 30px !important;
  border-bottom: #af0d0d 10px solid;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: center !important;
}

.bordergreen {
  font-family: 'Roboto', sans-serif;
  font-weight: 800 !important;
  font-size: 30px !important;
  border-bottom: #0daf4e 10px solid;
  padding-left: 5px;
  padding-right: 5px;
}

.jus {
  justify-content: center !important;
}

.text {
  font-size: 11px !important;
}

.stadt {
  font-family: 'Roboto', sans-serif;
  font-size: 35px !important;
  font-weight: lighter !important;
}

.time {
  float: right !important;
  font-family: 'Roboto', sans-serif;
  font-size: 12px !important;
  color: white !important;
  font-weight: 400;
}

.headertext {
  font-family: 'Oxanium', cursive !important;
  color: white !important;
  font-size: 80px !important;
}

.subtext {
  font-family: 'Oxanium', cursive !important;
  color: white !important;
  margin-left: 50px !important;
  font-size: 9px !important;
}

.MuiLink-underlineAlways {
  color: #ffffff !important;
}

.white {
  font-family: 'Oxanium', cursive !important;
  color: white;
  font-size: 13px;
}

.subtext {
  font-family: 'Oxanium', cursive !important;
  color: white !important;
  font-size: 20px !important;
  padding-top: 35px;
  padding-bottom: 35px;
  margin-left: 0px !important;
}
