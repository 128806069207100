@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oxanium&display=swap);
.Cards_margin__2WLo1 {
  margin: 10px !important;
}

.Cards_borderblue__3FzEZ {
  font-family: 'Roboto', sans-serif;
  font-weight: 800 !important;
  font-size: 30px !important;
  border-bottom: #0d4eaf 10px solid;
  padding-left: 5px;
  padding-right: 5px;
}

.Cards_borderred__3L54p {
  font-family: 'Roboto', sans-serif;
  font-weight: 800 !important;
  font-size: 30px !important;
  border-bottom: #af0d0d 10px solid;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: center !important;
}

.Cards_bordergreen__3blCl {
  font-family: 'Roboto', sans-serif;
  font-weight: 800 !important;
  font-size: 30px !important;
  border-bottom: #0daf4e 10px solid;
  padding-left: 5px;
  padding-right: 5px;
}

.Cards_jus__3sXb5 {
  justify-content: center !important;
}

.Cards_text__2YlWy {
  font-size: 11px !important;
}

.Cards_stadt__3JGSr {
  font-family: 'Roboto', sans-serif;
  font-size: 35px !important;
  font-weight: lighter !important;
}

.Cards_time__3O37A {
  float: right !important;
  font-family: 'Roboto', sans-serif;
  font-size: 12px !important;
  color: white !important;
  font-weight: 400;
}

.Cards_headertext__3QXw2 {
  font-family: 'Oxanium', cursive !important;
  color: white !important;
  font-size: 80px !important;
}

.Cards_subtext__2IBWa {
  font-family: 'Oxanium', cursive !important;
  color: white !important;
  margin-left: 50px !important;
  font-size: 9px !important;
}

.Cards_MuiLink-underlineAlways__36WT9 {
  color: #ffffff !important;
}

.Cards_white__3WPFu {
  font-family: 'Oxanium', cursive !important;
  color: white;
  font-size: 13px;
}

.Cards_subtext__2IBWa {
  font-family: 'Oxanium', cursive !important;
  color: white !important;
  font-size: 20px !important;
  padding-top: 35px;
  padding-bottom: 35px;
  margin-left: 0px !important;
}

body {
  background-image: linear-gradient(#0f2027, #203a43, #2c5364);
  background-attachment: fixed;
  height: 100%;
}

.Footer_white__1W1eS {
  font-size: 12px;
  color: white !important;
}

.Footer_white__1W1eS {
  font-size: 12px;
  color: white !important;
  padding-left: 15px;
}

.Footer_pad__1EdFU {
  padding-top: 100px !important;
}

